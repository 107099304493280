<template lang="">
  <div>
    <SubHeader :data="data" />
    <div class="container">
      <div class="container-text">
        <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as</P>
        <br/>
        <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as</P>
        <br/>
        <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as</P>
        <br/>
        <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as</P>
      </div>
    </div>
  </div>
</template>
<script>
import SubHeader from '../components/SubHeader.vue'
export default {
  name: 'Cookie Policy',
  components: {
    SubHeader
  },
  data() {
    return {
      data: {
        label_name: 'cookiePolicy',
        content_desc: 'cookiePolicyCotent',
        img: require('@/assets/image/sub-header/about-mos-app.png')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.container-text {
    font-size: 24px;
    font-weight: 500;
    padding: 40px 0 40px;
}
</style>
